<template>
  <v-container class="gallery">
    <v-row v-if="!loading && media && media.length > 0">
      <v-col cols="6" md="3" v-for="item in media" :key="item.id">
        <v-card>
          <v-img :src="item.image" aspect-ratio="1"
            ><template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row> </template
          ></v-img>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              color="primary"
              :to="{ name: 'Creator', params: { index: item.id } }"
            >
              Plakat erstellen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    selectedFolder: "",
    items: []
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    error() {
      return this.$store.state.loading;
    },
    media() {
      return this.$store.state.images;
    },
    getImages() {
      return this.media.filter(ref => ref.fullPath.includes("800x800"));
    }
  },
  methods: {
    resizedName(fileName, dimensions = "800x800") {
      const extIndex = fileName.lastIndexOf(".");
      const ext = fileName.substring(extIndex);
      return `${fileName.substring(0, extIndex)}_${dimensions}${ext}`;
    },

    fetchImages: function() {
      // set db refrences
      const gallery = this.$route.params.gallery;
      this.$store.dispatch("fetchGalleryImages", gallery);
    }
  },
  mounted() {
    // this.fetchMedia();
    this.fetchImages();
  }
};
</script>
